import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import dayjs from 'dayjs';

import AutocompleteWithInsert from '../../shared/autocomplete_with_insert';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormGroup } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { grades } from '../shared/utils';
import { useTheme } from '@mui/material/styles';
import { useConfirmDialog } from '../../modals/confirm_dialog';
import { DatePicker } from '@mui/x-date-pickers-pro';
import Attachments from './attachments';
import { canShowPercentEngaged, getHqimValue, showOtherHqimField } from './utils';

const getStylesForMultiSelect = (name, selectedHqimTypes, theme) => {
  return {
    fontWeight: selectedHqimTypes.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
};

const Meta = ({
  survey,
  observation,
  observables,
  schoolNames,
  contentAreas,
  setContentAreaId,
  instructionLanguages,
  setInstructionLanguageId,
  hqimTypes,
  handleSave,
  errors,
  readOnly,
  onObservableChange,
  showObservableAnalysis,
  showPredominantlyPoc,
  showHqim,
  showClassGrouping,
  isPlcMode,
  includesLanguageOfInstruction,
  notes,
  can_add_internal_teachers = false,
}) => {
  const [localObservation, setLocalObservation] = useState({
    ...observation,
    hqim_type_ids: observation?.hqim_types?.map((t) => t.id),
  });
  const [selectedHqimTypes, setSelectedHqimTypes] = useState(localObservation?.hqim_types?.map((type) => type.id) || []);

  const theme = useTheme();
  const { showConfirmDialog, hideConfirmDialog } = useConfirmDialog();

  const dateValue = localObservation?.observation_date ? dayjs(localObservation.observation_date) : null;

  const handleSaveChange = (updatedValues) => {
    const updatedObservation = { ...localObservation, ...updatedValues, notes: notes };
    setLocalObservation(updatedObservation);
    handleSave(updatedObservation);

    if ('observable' in updatedValues) {
      onObservableChange(updatedValues.observable);
    }
  };

  const debouncedHandleSave = useCallback(
    debounce((updatedObservation) => {
      handleSave(updatedObservation);
    }, 500),
    [handleSave]
  );

  const isComplete = () => localObservation?.status === 'completed';

  const confirmSaveAndMarkIncomplete = (data, cancelCallback = null, okCallback = null) => {
    showConfirmDialog({
      title: 'Save and Mark Incomplete?',
      description: 'Making this change will also mark the observation as incomplete. Do you want to continue?',
      actions: [
        {
          label: 'Cancel',
          variant: 'text',
          color: 'primary',
          onClick: () => {
            hideConfirmDialog();
            if (cancelCallback && typeof cancelCallback === 'function') {
              cancelCallback();
            }
          },
        },
        {
          label: 'Continue',
          variant: 'contained',
          color: 'primary',
          autoFocus: false,
          onClick: () => {
            hideConfirmDialog();
            const payload = { ...data, status: 'incomplete' };
            handleSaveChange(payload);
            if (okCallback && typeof okCallback === 'function') {
              okCallback();
            }
          },
        },
      ],
    });
  };

  const handleTextChange = (updatedValues) => {
    const updatedObservation = { ...localObservation, ...updatedValues };
    setLocalObservation(updatedObservation);
    debouncedHandleSave(updatedObservation);
  };

  const handleHqimChange = (event) => {
    const value = event.target.value === 'yes' ? true : event.target.value === 'no' ? false : null;
    handleSaveChange({ hqim_in_use: value });
  };

  const handleHqimTypeChange = (event) => {
    const updatedHqimTypeIds = event.target.value;
    if (updatedHqimTypeIds?.length === 0 && isComplete()) {
      confirmSaveAndMarkIncomplete({ hqim_type_ids: updatedHqimTypeIds }, null, () => {
        setSelectedHqimTypes(updatedHqimTypeIds);
      });
    } else {
      setSelectedHqimTypes(updatedHqimTypeIds);
      handleSaveChange({ hqim_type_ids: updatedHqimTypeIds });
    }
  };

  const areHqimFieldsDisabled = () => {
    return (
      !localObservation ||
      localObservation?.hqim_in_use === undefined ||
      localObservation?.hqim_in_use === null ||
      localObservation?.hqim_in_use === false
    );
  };

  const hasErrors = (prop) => errors && errors[prop]?.length > 0;

  const errorMessage = (prop) => (hasErrors(prop) ? errors[prop][0] : '');

  const renderPercentEngaged = () => {
    const { number_of_engaged_students, number_of_students } = localObservation || {};
    const percent = (Number(number_of_engaged_students) / Number(number_of_students)) * 100;
    const displayValue = percent % 1 === 0 ? percent : percent.toFixed(2);

    return (
      <Grid item xs={4}>
        <Typography variant="h6">={displayValue}%</Typography>
      </Grid>
    );
  };

  const fieldStyle = {
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
    },
    '& .MuiSelect-select': {
      backgroundColor: 'white',
    },
  };

  return (
    <Box sx={{ marginTop: 5, paddingX: 5, paddingY: 5, borderRadius: 2, border: '1px solid #ccc', backgroundColor: '#F5F8FA' }}>
      <div>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Typography variant="body2" color="textSecondary">
              {survey.category_name} - {survey.survey_type_name}
            </Typography>
            <Typography variant="h6">{survey.name}</Typography>
            <div className="d-none">Observation ID: {localObservation?.id}</div>
          </Stack>
          <Attachments observation={observation} />
        </Stack>
      </div>
      <form>
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={4}>
            <DatePicker
              readOnly={readOnly}
              label="Date"
              value={dateValue}
              onChange={(date) => {
                const value = date ? date.format('YYYY-MM-DD') : null;
                if (!value && isComplete()) {
                  confirmSaveAndMarkIncomplete({ observation_date: value });
                } else {
                  handleSaveChange({ observation_date: value });
                }
              }}
              slotProps={{
                textField: {
                  required: true,
                  fullWidth: true,
                  error: hasErrors('observation_date'),
                  helperText: errorMessage('observation_date'),
                  InputLabelProps: { shrink: true },
                  size: 'small',
                  sx: fieldStyle,
                },
              }}
            />
          </Grid>

          <Grid item xs={8}>
            <FormControl fullWidth required error={hasErrors('school_name')} size="small" disabled={readOnly}>
              <AutocompleteWithInsert
                readOnly={readOnly || survey.is_school}
                required={true}
                initialValue={localObservation?.school_name || null}
                options={schoolNames}
                label="School Name"
                inputId="school_name_autocomplete"
                nameLabel="School Name"
                dialogTitle={`Add School Name to ${survey.org_name}`}
                onChange={(schoolName) => handleSaveChange({ school_name: schoolName })}
                onRemove={(value, callback) => {
                  if (isComplete()) {
                    confirmSaveAndMarkIncomplete(
                      { school_name: null },
                      () => callback(false),
                      () => callback(true)
                    );
                  } else {
                    handleSaveChange({ school_name: null });
                    callback(true);
                  }
                }}
                errors={errors['school_name']}
                sx={fieldStyle}
              />
              <FormHelperText>{errorMessage('school_name')}</FormHelperText>
            </FormControl>
          </Grid>

          {!isPlcMode && (
            <Grid item xs={4}>
              <FormControl fullWidth required error={hasErrors('grade_level')} size="small" disabled={readOnly}>
                <InputLabel id="grade-level-select-label">Grade Level</InputLabel>
                <Select
                  labelId="grade-level-select-label"
                  id="grade-level-select"
                  value={localObservation?.grade_level || ''}
                  label="Grade Level"
                  onChange={(e) => handleSaveChange({ grade_level: e.target.value })}
                  sx={fieldStyle}
                >
                  {grades.map((grade, index) => (
                    <MenuItem key={index} value={grade}>
                      {grade}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errorMessage('grade_level')}</FormHelperText>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={isPlcMode ? 12 : 8}>
            <AutocompleteWithInsert
              readOnly={readOnly}
              initialValue={localObservation?.observable || null}
              options={observables}
              label={isPlcMode ? 'Observation Of' : survey.observable_types.map((type) => type.name).join(' or ')}
              inputId="observable_name_autocomplete"
              nameLabel={`Teacher Name`}
              dialogTitle={`Add Teacher to ${survey.org_name}`}
              onChange={(observable) => handleSaveChange({ observable: observable })}
              onRemove={(value, callback) => {
                handleSaveChange({ observable: null });
                callback(true);
              }}
              sx={fieldStyle}
              allowNewItems={can_add_internal_teachers}
            />
          </Grid>

          {!isPlcMode && includesLanguageOfInstruction && (
            <Grid item xs={4}>
              <FormControl fullWidth error={hasErrors('instruction_language')} size="small" disabled={readOnly}>
                <InputLabel id="instruction-language-select-label">Language of Instruction</InputLabel>
                <Select
                  labelId="instruction-language-select-label"
                  id="instruction-language-select"
                  value={localObservation?.instruction_language?.id || ''}
                  label="Language of Instruction"
                  onChange={(e) => {
                    const selectedId = e.target.value;
                    const selected = instructionLanguages.find((ca) => ca.id === selectedId);
                    setInstructionLanguageId(selectedId);
                    handleSaveChange({
                      instruction_language_id: selectedId,
                      instruction_language: selected,
                    });
                  }}
                  sx={fieldStyle}
                >
                  {instructionLanguages.map((lang, index) => (
                    <MenuItem key={index} value={lang.id}>
                      {lang.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errorMessage('instruction_language')}</FormHelperText>
              </FormControl>
            </Grid>
          )}

          {!isPlcMode && (
            <Grid item xs={8}>
              <FormControl fullWidth required error={hasErrors('content_area')} size="small" disabled={readOnly}>
                <InputLabel id="content-select-label">Content</InputLabel>
                <Select
                  labelId="content-select-label"
                  id="content-select"
                  value={localObservation?.content_area?.id || ''}
                  label="Content"
                  onChange={(e) => {
                    const selectedContentAreaId = e.target.value;
                    const selectedContentArea = contentAreas.find((ca) => ca.id === selectedContentAreaId);
                    setContentAreaId(selectedContentAreaId);
                    handleSaveChange({
                      content_area_id: selectedContentAreaId,
                      content_area: selectedContentArea,
                    });
                  }}
                  sx={fieldStyle}
                >
                  {contentAreas.map((contentArea, index) => (
                    <MenuItem key={index} value={contentArea.id}>
                      {contentArea.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errorMessage('content_area')}</FormHelperText>
              </FormControl>
            </Grid>
          )}

          {localObservation.content_area?.show_comments && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={readOnly}
                label="Other Content"
                variant="outlined"
                value={localObservation?.other_content || ''}
                id="other_content"
                onChange={(e) => handleTextChange({ other_content: e.target.value })}
                size="small"
                sx={fieldStyle}
              />
            </Grid>
          )}
        </Grid>

        {showClassGrouping && (
          <Grid container spacing={2} marginTop={3} alignItems="center">
            <Grid item xs={12}>
              <Box sx={{ display: 'flex' }}>
                <FormControl component="fieldset" variant="outlined" disabled={readOnly}>
                  <FormLabel component="legend" className="mb-0">
                    Groupings Observed
                  </FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={localObservation?.whole_group || false}
                          onChange={(e) => handleSaveChange({ whole_group: e.target.checked })}
                          name="whole_group"
                        />
                      }
                      label="whole group"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={localObservation?.small_group || false}
                          onChange={(e) => handleSaveChange({ small_group: e.target.checked })}
                          name="small_group"
                        />
                      }
                      label="small group"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={localObservation?.independent || false}
                          onChange={(e) => handleSaveChange({ independent: e.target.checked })}
                          name="indepentend"
                        />
                      }
                      label="independent"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={4} marginTop={0}>
              <TextField
                disabled={readOnly}
                error={hasErrors('number_of_students')}
                helperText={errorMessage('number_of_students')}
                fullWidth
                required
                label="Number of Students"
                type="number"
                variant="outlined"
                id="number_of_students"
                value={localObservation?.number_of_students || ''}
                onChange={(e) => {
                  const value = e.target.value?.trim();
                  if (!value && isComplete()) {
                    confirmSaveAndMarkIncomplete({ number_of_students: value });
                  } else {
                    handleSaveChange({ number_of_students: value });
                  }
                }}
                InputLabelProps={{ shrink: true }}
                inputProps={{ min: 0 }}
                size="small"
                sx={fieldStyle}
              />
            </Grid>
            <Grid item xs={4} marginTop={0}>
              <TextField
                disabled={readOnly}
                error={hasErrors('number_of_engaged_students')}
                helperText={errorMessage('number_of_engaged_students')}
                fullWidth
                required
                label="Number of Engaged Students"
                type="number"
                variant="outlined"
                id="number_of_engaged_students"
                value={localObservation?.number_of_engaged_students || ''}
                onChange={(e) => {
                  const value = e.target.value?.trim();
                  if (!value && isComplete()) {
                    confirmSaveAndMarkIncomplete({ number_of_engaged_students: value });
                  } else {
                    handleSaveChange({ number_of_engaged_students: value });
                  }
                }}
                InputLabelProps={{ shrink: true }}
                inputProps={{ min: 0 }}
                size="small"
                sx={fieldStyle}
              />
            </Grid>
            {canShowPercentEngaged(localObservation) && renderPercentEngaged()}
          </Grid>
        )}

        {showPredominantlyPoc && (
          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={12}>
              <FormControl error={hasErrors('diverse_classroom')} disabled={readOnly}>
                <FormLabel id="diverse-classroom-radio-group-label">Classroom with Predominantly Students of Color</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="diverse-classroom-radio-group-label"
                  name="diverse-classroom-radio-group"
                  value={localObservation?.diverse_classroom || ''}
                  onChange={(e) => handleSaveChange({ diverse_classroom: e.target.value })}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                  <FormControlLabel value="Unclear" control={<Radio />} label="Unclear" />
                </RadioGroup>
                <FormHelperText>{errorMessage('diverse_classroom')}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        )}

        {showHqim && (
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={12}>
              <FormControl required error={hasErrors('hqim_in_use')} disabled={readOnly}>
                <FormLabel id="hqim-in-use-radio-group-label">HQIM In Use</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="hqim-in-use-radio-group-label"
                  name="hqim-in-use-radio-group"
                  value={getHqimValue(localObservation)}
                  onChange={handleHqimChange}
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
                <FormHelperText>{errorMessage('hqim_in_use')}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required error={hasErrors('hqim_type')} size="small">
                <InputLabel id="hqim-type-select-label">HQIM Types</InputLabel>
                <Select
                  disabled={areHqimFieldsDisabled() || readOnly}
                  labelId="hqim-type-select-label"
                  id="hqim-type-select"
                  multiple
                  value={selectedHqimTypes}
                  onChange={handleHqimTypeChange}
                  input={<OutlinedInput id="select-multiple-chip-hqim" label="HQIM Types" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={hqimTypes.find((type) => type.id === value)?.name} />
                      ))}
                    </Box>
                  )}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 4.5 + 8,
                        width: 250,
                      },
                    },
                  }}
                  sx={fieldStyle}
                >
                  {hqimTypes.map((hqimType) => (
                    <MenuItem key={hqimType.id} value={hqimType.id} style={getStylesForMultiSelect(hqimType.name, selectedHqimTypes, theme)}>
                      {hqimType.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errorMessage('hqim_type')}</FormHelperText>
              </FormControl>
            </Grid>
            {showOtherHqimField(localObservation, hqimTypes) && (
              <Grid item xs={12}>
                <TextField
                  disabled={areHqimFieldsDisabled()}
                  fullWidth
                  label="Other HQIM In Use"
                  multiline
                  variant="outlined"
                  value={localObservation?.other_hqim || ''}
                  id="other_hqim_type"
                  onChange={(e) => handleTextChange({ other_hqim: e.target.value })}
                  size="small"
                  sx={fieldStyle}
                />
              </Grid>
            )}
          </Grid>
        )}
      </form>
    </Box>
  );
};

Meta.propTypes = {
  survey: PropTypes.object.isRequired,
  observation: PropTypes.object,
  observables: PropTypes.array,
  schoolNames: PropTypes.array,
  contentAreas: PropTypes.array,
  setContentAreaId: PropTypes.func,
  instructionLanguages: PropTypes.array,
  setInstructionLanguageId: PropTypes.func,
  hqimTypes: PropTypes.array,
  handleSave: PropTypes.func,
  errors: PropTypes.object,
  readOnly: PropTypes.bool,
};

Meta.observation = {
  id: PropTypes.number,
  observation_date: PropTypes.string,
  school_name: PropTypes.object,
  observable: PropTypes.object,
  grade_level: PropTypes.string,
  number_of_students: PropTypes.number,
  number_of_engaged_students: PropTypes.number,
  whole_group: PropTypes.bool,
  small_group: PropTypes.bool,
  independent: PropTypes.bool,
  hqim_in_use: PropTypes.bool,
  other_hqim: PropTypes.string,
  content_area: PropTypes.object,
  hqim_types: PropTypes.array,
  diverse_classroom: PropTypes.string,
};

export default Meta;
